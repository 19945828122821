<!-- eslint-disable no-unused-vars -->
<template>
  <b-card title="" class="text-left">
    <b-form>
      <span v-if="user.deleted_at" :style="{ color: 'red' }"
        >Usuário desativado</span
      >
      <!-- Nome | init -->
      <b-row class="my-1 m-2 pl-2 pr-2 text-left d-flex justify-content-center">
        <b-col col xs="12" sm="12" md="6" lg="6">
          <b-form-group>
            <label for="value" class="text-center font-weight-bold text-primary"
              >Nome :</label
            >

            <b-input-group>
              <BFormInput
                id="value"
                type="text"
                v-model="user.username"
              ></BFormInput>
            </b-input-group>
          </b-form-group>
        </b-col>
      </b-row>
      <!-- Nome | end -->

      <!-- Email | init -->
      <b-row class="my-1 m-2 pl-2 pr-2 text-left d-flex justify-content-center">
        <b-col col xs="12" sm="12" md="6" lg="6">
          <b-form-group>
            <label for="value" class="text-center font-weight-bold text-primary"
              >Email :</label
            >

            <b-input-group>
              <BFormInput
                id="value"
                type="text"
                v-model="user.email"
              ></BFormInput>
            </b-input-group>
          </b-form-group>
        </b-col>
      </b-row>
      <!-- Email | end -->

      <!-- Custo mes | init -->
      <b-row class="my-1 m-2 pl-2 pr-2 text-left d-flex justify-content-center">
        <b-col col xs="12" sm="12" md="6" lg="6">
          <b-form-group>
            <label for="value" class="text-center font-weight-bold text-primary"
              >Custo / mês :</label
            >

            <b-input-group prepend="R$">
              <BFormInput
                id="value"
                type="text"
                v-model="item.cost_per_month"
                v-money="moneyMask"
              ></BFormInput>
            </b-input-group>
          </b-form-group>
        </b-col>
      </b-row>
      <!-- Custo mes | end -->

      <!-- Hora mes | init -->
      <b-row class="my-1 m-2 pl-2 pr-2 text-left d-flex justify-content-center">
        <b-col col xs="12" sm="12" md="6" lg="6">
          <b-form-group>
            <label for="value" class="text-center font-weight-bold text-primary"
              >Hora / mês :</label
            >

            <b-input-group prepend="Hora">
              <BFormInput
                id="value"
                type="number"
                v-model="item.hour_per_month"
              ></BFormInput>
            </b-input-group>
          </b-form-group>
        </b-col>
      </b-row>
      <!-- Hora mes | end -->

      <!-- Taxa de improdutividade -->
      <b-row class="my-1 m-2 pl-2 pr-2 text-left d-flex justify-content-center">
        <b-col col xs="12" sm="12" md="6" lg="6">
          <b-form-group>
            <label for="value" class="text-center font-weight-bold text-primary"
              >Improdutividade :</label
            >

            <b-input-group prepend="%">
              <BFormInput
                id="value"
                type="text"
                v-model="item.unproductively"
              ></BFormInput>
            </b-input-group>
          </b-form-group>
        </b-col>
      </b-row>

      <!-- Cor -->
      <b-row class="my-1 m-2 pl-2 pr-2 text-left d-flex justify-content-center">
        <b-col col xs="12" sm="12" md="6" lg="6">
          <b-form-group>
            <label for="value" class="text-center font-weight-bold text-primary"
              >Cor :</label
            >

            <BFormInput
              id="value"
              type="color"
              v-model="item.color"
            ></BFormInput>
          </b-form-group>
        </b-col>
      </b-row>

      <hr />
      <div class="w-100 d-flex flex-column align-items-center">
        <h2 class="text-center font-weight-bold text-primary pb-1">Tipo de acesso</h2>
        <b-row
        class="my-1 m-2 pl-2 pr-2 text-left d-flex flex-column align-items-start"
      >
        <b-col cols="12" >
          <b-form-group>
            <b-form-checkbox
              switch
              :disabled="loading || item.is_blocked"
              v-model="item.is_admin"
              class="text-left"
              name="checkbox-1"
            >
              Administrador da {{ currentWorkspace.workspace_title }}
            </b-form-checkbox>
          </b-form-group>
        </b-col>

        

        <b-col cols="12" >
          <b-form-group>
            <b-form-checkbox
              switch
              class="text-left"
              :disabled="loading || !currentUser.is_admin"
              v-model="item.is_manager"
              name="checkbox-3"
            >
              Gerente
            </b-form-checkbox>
          </b-form-group>
        </b-col>

        <b-col cols="12">
          <b-form-group>
            <b-form-checkbox
              switch
              class="text-left"
              :disabled="loading || !currentUser.is_admin"
              v-model="item.is_transaction_manager"
              name="checkbox-4"
            >
              Financeiro
            </b-form-checkbox>
          </b-form-group>
        </b-col>
        <b-col cols="12">
          <b-form-group>
            <b-form-checkbox
              class="text-left"
              :disabled="loading || !currentUser.is_admin"
              v-model="item.is_blocked"
              name="checkbox-2"
            >
              Bloqueado
            </b-form-checkbox>
          </b-form-group>
        </b-col>
      </b-row>
      </div>
      

      <!-- 200 - Modal de ativação -->
      <b-modal
        v-model="visibleInactive"
        hide-footer
        hide-header-close
        no-close-on-backdrop
        modal-class="modal-primary"
        centered
        title="Atenção!"
      >
        <b-form-group
          :style="{
            gap: '30px',
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
          }"
        >
          <label
            :style="{
              fontSize: '1rem',
              marginBottom: '30px',
            }"
            for="value"
            >Deseja realmente {{ !user.deleted_at ? 'ativar' : 'desativar' }}
            <strong>{{ user.username }}</strong
            >?</label
          >

          <div
            :style="{
              gap: '30px',
              width: '100%',
              display: 'flex',
              justifyContent: 'center',
            }"
          >
            <!-- Voltar -->
            <BButton variant="outline" @click="visibleInactive = false"
              >Voltar</BButton
            >
            <!-- Confirmar -->
            <BButton
              :variant="!user.deleted_at ? 'danger' : 'success'"
              @click="removeUser()"
              >{{ !user.deleted_at ? 'Inativar' : 'Ativar' }}</BButton
            >
          </div>
        </b-form-group>
      </b-modal>
      <!-- 210 - Modal de ativação -->
    </b-form>

    <div :style="{ gap: '30px' }" class="justify-center">
      <!-- BACK -->
      <BButton variant="warning" @click="$router.push('/colaboradores')"
        >Voltar</BButton
      >
      <!-- ATIVATE/DESATIVATE Modal -->
      <BButton
        :variant="!user.deleted_at ? 'danger' : 'success'"
        @click="visibleInactive = true"
        >{{ user.deleted_at ? 'Reativar' : 'Inativar' }}</BButton
      >

      <!-- SAVE -->
      <BButton variant="primary" @click="updateItem">Salvar</BButton>
    </div>
  </b-card>
</template>

<script>
import {
  BCard,
  BButton,
  BForm,
  BFormGroup,
  BFormInput,
  BCol,
  BRow,
  BInputGroup,
  BModal,
  BFormCheckbox,
} from 'bootstrap-vue';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
import { VBTooltip } from 'bootstrap-vue';

export default {
  directives: {
    'b-tooltip': VBTooltip,
  },
  components: {
    BCard,
    BFormInput,
    BModal,
    BCol,
    BRow,
    BForm,
    BFormGroup,
    BButton,
    BInputGroup,
    BFormCheckbox,
  },
  data: () => ({
    item: { color: '#FFF' },
    user: {},
    loading: false,
    visibleInactive: false,
    moneyMask: {
      suffix: '',
      thousands: '.',
      decimal: ',',
      precision: 2,
      masked: false,
    },
  }),

  created() {
    this.getItem();
    console.log('sadasdasd',this.currentUser)
  },

  computed: {
    currentWorkspace() {
      return this.$store.getters.currentWorkspace;
    },
    currentUser() {
      return this.$store.getters.user.accesses.find(
        (a) => a.workspace_id == this.$store.getters.currentWorkspace.id
      );
    },
  },

  methods: {
    async getItem() {
      await this.$store
        .dispatch('getOneWorkspaceMembers', {
          member_id: this.$route.params.id,
          workspace_id: this.$store.getters.currentWorkspace.id,
        })
        .then(async (resp) => {
          this.item = resp[0];
          console.log(resp)

          await this.getFullInformation();
        })
        .catch((err) => {
          console.log(err);
        });
    },

    async getFullInformation() {
      this.loading = true;
      await this.$store
        .dispatch('getUser', { id: this.item.user_id })
        .then((resp) => {
          this.loading = false;
          this.user = resp;
        })
        .catch(() => {
          this.loading = false;

          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Erro buscar usuário',
              icon: 'XIcon',
              variant: 'danger',
            },
          });
        });
    },

    async updateItem() {
      this.$store
        .dispatch('updateOneWorkspaceMembers', {
          color: this.item.color,
          unproductively: this.item.unproductively,
          hour_per_month: this.item.hour_per_month,
          cost_per_month: this.monetaryToNumber(this.item.cost_per_month),
          member_id: this.$route.params.id,
          is_admin: this.item.is_admin,
          is_blocked: this.item.is_blocked,
          is_manager: this.item.is_manager,
          is_transaction_manager: this.item.is_transaction_manager,
          workspace_id: this.currentWorkspace.id

        })
        .then(() => {
          this.$store.dispatch('updateUser', this.user).then(() => {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Alterado com sucesso.',
                icon: 'SuccessIcon',
                variant: 'success',
              },
            });

            this.loading = false;
            this.$router.push({
              name: 'colaboradores',
            });
          });
        })
        .catch(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Erro ao atualizar user.',
              icon: 'XIcon',
              variant: 'danger',
            },
          });
        });
    },

    async removeUser() {
      await this.$store
        .dispatch('updateUser', {
          ...this.user,
          deleted_at: this.user.deleted_at ? null : new Date(),
        })
        .then((resp) => {
          if (resp) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Usuário removido com sucesso',
                icon: 'SuccessIcon',
                variant: 'success',
              },
            });
            this.$router.push({ name: 'colaboradores' });
          }
        })
        .catch(() => {
          this.loading = false;
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Erro ao deletar usuário',
              icon: 'XIcon',
              variant: 'danger',
            },
          });
        });
    },
  },
};
</script>
<style>
.justify-center {
  display: flex;
  justify-content: center;
}
td::before {
  float: none !important;
}

.w-100 {
  width: 100%!important;
}
</style>
